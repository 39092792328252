import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Box, Stack, CircularProgress, IconButton, Typography
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { Product } from '../../types/admin';

interface ProductsViewProps {
  products: Product[];
  loadingDelist: string;
  onAddProduct: () => void;
  onEditQuantity: (product: Product) => void;
  onEditProduct: (product: Product) => void;
  onDelistProduct: (productId: string, currentDelisted: boolean) => Promise<void>;
}

export const ProductsView: React.FC<ProductsViewProps> = ({
  products,
  loadingDelist,
  onAddProduct,
  onEditQuantity,
  onEditProduct,
  onDelistProduct,
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onAddProduct}
        >
          Add Product
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow 
                key={product.id}
                sx={{
                  bgcolor: product.delisted ? 'action.disabledBackground' : 'inherit'
                }}
              >
                <TableCell>
                  <img 
                    src={product.photos[0]} 
                    alt={product.title} 
                    style={{ width: 50, height: 50, objectFit: 'cover' }}
                  />
                </TableCell>
                <TableCell>{product.title}</TableCell>
                <TableCell>₹{product.price.toFixed(2)}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {product.quantity}
                    <IconButton 
                      size="small"
                      onClick={() => onEditQuantity(product)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'white',
                      bgcolor: product.delisted ? 'error.main' : 'success.main',
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      display: 'inline-block'
                    }}
                  >
                    {product.delisted ? 'DELISTED' : 'ACTIVE'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      color={product.delisted ? "primary" : "error"}
                      size="small"
                      onClick={() => onDelistProduct(product.id, product.delisted || false)}
                      disabled={loadingDelist === product.id}
                    >
                      {loadingDelist === product.id ? (
                        <CircularProgress size={20} />
                      ) : (
                        product.delisted ? 'Relist' : 'Delist'
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => onEditProduct(product)}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}; 