import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  FormControl, Select, MenuItem, Button, Box, Typography, Stack, Grid
} from '@mui/material';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { OrderBatch } from '../../types/admin';

interface BatchesViewProps {
  batches: OrderBatch[];
  selectedBatch: OrderBatch | null;
  onBatchSelect: (batch: OrderBatch | null) => void;
  onFinalizeBatch: (batchId: string) => Promise<void>;
}

export const BatchesView: React.FC<BatchesViewProps> = ({
  batches,
  selectedBatch,
  onBatchSelect,
  onFinalizeBatch,
}) => {
  const [batchProducts, setBatchProducts] = useState<{[key: string]: {
    title: string;
    quantity: number;
    total: number;
  }}>({});

  useEffect(() => {
    const loadBatchDetails = async () => {
      if (!selectedBatch) return;
      
      const batchOrders = await Promise.all(
        selectedBatch.orders.map(orderId => 
          getDoc(doc(db, 'orders', orderId))
        )
      );

      const productTotals: {[key: string]: {
        title: string;
        quantity: number;
        total: number;
      }} = {};

      batchOrders.forEach(orderDoc => {
        const order = orderDoc.data() as any;
        order.items.forEach((item: any) => {
          if (!productTotals[item.productId]) {
            productTotals[item.productId] = {
              title: item.title,
              quantity: 0,
              total: 0
            };
          }
          productTotals[item.productId].quantity += item.quantity;
          productTotals[item.productId].total += item.itemTotal;
        });
      });

      setBatchProducts(productTotals);
    };

    loadBatchDetails();
  }, [selectedBatch]);

  const grandTotal = Object.values(batchProducts).reduce(
    (sum, product) => sum + product.total,
    0
  );
  const totalQuantity = Object.values(batchProducts).reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  const handlePrintShippingLabels = () => {
    if (selectedBatch) {
      window.open(`/batchShipping/${selectedBatch.id}`, '_blank');
    }
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <Select
            value={selectedBatch?.batchNumber ?? ''}
            onChange={(e) => {
              const batch = batches.find(b => b.batchNumber === e.target.value);
              onBatchSelect(batch || null);
            }}
          >
            {batches.map((batch) => (
              <MenuItem key={batch.id} value={batch.batchNumber}>
                Batch #{batch.batchNumber} 
                {batch.finalized ? ' (Finalized)' : ' (Active)'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {selectedBatch && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(batchProducts).map(([productId, data]) => (
                  <TableRow key={productId}>
                    <TableCell>{data.title}</TableCell>
                    <TableCell>{data.quantity}</TableCell>
                    <TableCell>₹{data.total.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ 
            mt: 3, 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center' 
          }}>
            <Stack spacing={1}>
              <Typography variant="h6">
                Total Items: {totalQuantity}
              </Typography>
              <Typography variant="h6">
                Grand Total: ₹{grandTotal.toFixed(2)}
              </Typography>
            </Stack>
            
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrintShippingLabels}
                disabled={!selectedBatch.orders.length}
              >
                Print Shipping Labels
              </Button>
              {!selectedBatch.finalized && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onFinalizeBatch(selectedBatch.id)}
                >
                  Finalize Batch
                </Button>
              )}
            </Stack>
          </Box>
        </>
      )}
    </>
  );
}; 