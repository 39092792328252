import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Button,
  Grid,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Avatar,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CloseIcon from '@mui/icons-material/Close';
import { getCart } from '../utils/cartUtils';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { httpsCallable, Functions, getFunctions } from 'firebase/functions';
import { storage } from '../config/firebase';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';

const functions = getFunctions();

interface PaymentPageProps {
  // Add any props if needed
}

interface UPIDialogProps {
  open: boolean;
  onClose: () => void;
  onComplete: (orderId: string) => void;
  total: number;
  cartItems: any[];
  shippingDetails: any;
  orderId: string | null;
}

const UPIDialog: React.FC<UPIDialogProps> = ({ 
  open, 
  onClose, 
  onComplete, 
  total = 0,
  cartItems = [], 
  shippingDetails = {}, 
  orderId 
}) => {
  const [receipt, setReceipt] = useState<File | null>(null);
  const [qrLoading, setQrLoading] = useState(true);
  const [isCompleting, setIsCompleting] = useState(false);
  const phoneNumber = '8119010954';
  const upiUrl = `upi://pay?pa=noellesailo2526@okhdfcbank&pn=Store%20Payment&am=${total}&cu=INR&tn=${orderId}&tr=${Date.now()}`;
  const [copySuccess, setCopySuccess] = useState(false);

  const handleUpiAppClick = (e: React.MouseEvent, app: keyof typeof appUrls) => {
    e.preventDefault();
    
    const appUrls = {
      'Google Pay': `gpay://upi/pay?pa=noellesailo2526@okhdfcbank&pn=Store%20Payment&am=${total}&cu=INR&tn=${orderId}`,
      'PhonePe': `phonepe://pay?pa=noellesailo2526@okhdfcbank&pn=Store%20Payment&am=${total}&cu=INR&tn=${orderId}`,
      'Paytm': `paytmmp://pay?pa=noellesailo2526@okhdfcbank&pn=Store%20Payment&am=${total}&cu=INR&tn=${orderId}`
    } as const;

    window.location.href = appUrls[app] || upiUrl;
  };

  const handleComplete = async () => {
    setIsCompleting(true);
    try {
      if (!receipt) {
        throw new Error('No receipt image provided');
      }
      if (!orderId) {
        throw new Error('No order ID provided');
      }

      // 1. Upload receipt image to Firebase Storage
      const timestamp = Date.now();
      const fileName = `receipts/${timestamp}_${receipt.name}`;
      const storageRef = ref(storage, fileName);
      const uploadResult = await uploadBytes(storageRef, receipt);
      const receiptUrl = await getDownloadURL(uploadResult.ref);
      
      // 2. Call payOrder function with error logging
      const payOrderFunction = httpsCallable<{ orderId: string, receiptUrl: string }, { success: boolean }>(
        functions, 
        'payOrder'
      );
      
      console.log('Calling payOrder with:', { orderId, receiptUrl }); // Debug log
      
      const result = await payOrderFunction({ orderId, receiptUrl });
      console.log('payOrder result:', result); // Debug log



      // 4. Call the passed onComplete prop
      onComplete(orderId);

      // After successful payment
      localStorage.removeItem('pendingOrder');
      window.location.href = `/order/${orderId}`;
    } catch (error: any) {
      console.error('Error processing payment:', error);
      // More detailed error message
      alert(`Error processing payment: ${error.message || 'Please try again.'}`);
    } finally {
      setIsCompleting(false);
      onClose();
    }
  };

  const handleCopyNumber = async () => {
    try {
      await navigator.clipboard.writeText(phoneNumber);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: '450px',
          height: '100%',
          maxHeight: { xs: '100vh', sm: '90vh' },
          margin: { xs: 0, sm: 2 }
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid #e0e0e0',
        pb: 1,
        fontWeight: 'bold'
      }}>
        UPI Payment
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ 
        p: 3,
        overflowY: 'auto',
        '&::-webkit-scrollbar': { width: '8px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#bdbdbd', borderRadius: '4px' }
      }}>
        <Box sx={{ textAlign: 'center', mb: 0 }}>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
            1. Scan QR Code or Click App to Pay
          </Typography>
          <Box sx={{ position: 'relative', width: 150, height: 150, margin: '0 auto' }}>
            {qrLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <img 
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(upiUrl)}`}
              alt="UPI QR Code"
              style={{ width: 150, height: 150, marginBottom: 8 }}
              onLoad={() => setQrLoading(false)}
            />
          </Box>

          <List sx={{ mt: 1 }}>
            {[
              { name: 'Open Google Pay', logo: 'https://w7.pngwing.com/pngs/667/120/png-transparent-google-pay-2020-hd-logo-thumbnail.png' },
            ].map((app) => (
              <ListItem 
                key={app.name} 
                disablePadding 
                sx={{ mb: 1 }}
              >
                <ListItemButton 
                  onClick={(e) => handleUpiAppClick(e, app.name as "Google Pay")}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #e0e0e0',
                    borderRadius: 1,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': { 
                      bgcolor: '#f5f5f5',
                      border: '1px solid #000',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.12)',
                      transform: 'translateY(-1px)'
                    },
                    py: 1
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>{app.name}</Typography>
                  <Avatar 
                    src={app.logo} 
                    alt={app.name}
                    sx={{ 
                      width: 32, 
                      height: 32,
                      ml: 2,
                      bgcolor: 'transparent'
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>

        <Typography variant="subtitle2" sx={{ mb: 1, color: 'red', fontWeight: 'bold', fontStyle: 'italic' }}>
          Having trouble? Try paying <Box component="span" sx={{ fontSize: '1.1em', bgcolor: '#fff3cd', px: 1, py: 0.5, borderRadius: 1 }}>₹{total}</Box> to the GPay Phone Number: 
        </Typography>
        <Button
          startIcon={<ContentCopyIcon />}
          variant="outlined"
          onClick={handleCopyNumber}
          fullWidth
          sx={{ 
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            border: '1px solid #e0e0e0',
            borderRadius: 1,
            boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
            transition: 'all 0.2s ease-in-out',
            py: 1,
            '&:hover': { 
              bgcolor: '#f5f5f5',
              border: '1px solid #000',
              boxShadow: '0 4px 8px rgba(0,0,0,0.12)',
              transform: 'translateY(-1px)'
            }
          }}
        >
          {copySuccess ? 'Copied!' : `${phoneNumber}`}
        </Button>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
          2. Upload Payment Receipt
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Please take a screenshot of your payment confirmation and upload it here. Please make sure the screenshot shows:
        </Typography>

        <Box component="ul" sx={{ mb: 2, pl: 2 }}>
          <Typography component="li" variant="body2" color="text.secondary">
            UPI Transaction ID
          </Typography>
          <Typography component="li" variant="body2" color="text.secondary">
            Payment amount (₹{total.toFixed(2)})
          </Typography>
        </Box>
        <Box sx={{ 
          p: 2, 
          borderTop: '1px solid #e0e0e0',
          position: 'sticky',
          bottom: 0,
          bgcolor: 'background.paper',
          display: 'flex',
          gap: 2,
        }}>
          <Button
            component="label"
            variant="outlined"
            sx={{ 
              flex: 1,
              borderRadius: 2,
              border: '1px solid #e0e0e0',
              justifyContent: 'center',
              textAlign: 'center',
              '&:hover': {
                border: '1px solid #000',
              }
            }}
          >
            {receipt ? 'Receipt Added ✓' : 'Upload Screenshot'}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => setReceipt(e.target.files?.[0] || null)}
            />
          </Button>
          <Button
            variant="contained"
            disabled={!receipt || isCompleting}
            onClick={handleComplete}
            sx={{ 
              flex: 1,
              borderRadius: 2,
              bgcolor: '#ffd814',
              color: 'black',
              '&:hover': {
                bgcolor: '#f7ca00'
              }
            }}
          >
            {isCompleting ? (
              <CircularProgress size={24} sx={{ color: 'black' }} />
            ) : (
              'Complete Order'
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const PaymentPage: React.FC = () => {
  let { orderId: urlOrderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [openUPIDialog, setOpenUPIDialog] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        // Check if this is a valid pending order
        const pendingOrderId = localStorage.getItem('pendingOrder');
        let finalOrderId = urlOrderId || pendingOrderId;

        if (!finalOrderId) {
          navigate('/cart');
          return;
        }

        const orderDoc = await getDoc(doc(db, 'orders', finalOrderId));
        if (!orderDoc.exists()) {
          navigate('/cart');
          return;
        }

        const orderData = orderDoc.data();
        
        // If order is already paid, redirect to order page
        if (orderData.receiptUrl) {
          localStorage.removeItem('pendingOrder');
          navigate(`/order/${finalOrderId}`);
          return;
        }

        setCurrentOrderId(finalOrderId);
        setOrder(orderData);
      } catch (error) {
        console.error('Error:', error);
        navigate('/cart');
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [urlOrderId, navigate]);

  const handlePaymentComplete = (orderId: string) => {
    navigate(`/order/${orderId}`);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Payment
      </Typography>

      <Grid container spacing={4}>
        
        {/* Order Summary */}
        <Grid item xs={12} md={4}>
          <Paper elevation={0} sx={{ border: '1px solid #e0e0e0', borderRadius: 2, p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Order Summary
            </Typography>
            <Box sx={{ my: 2 }}>
              <Stack spacing={2}>
                {/* Add unique key using item.id and index as fallback */}
                {order?.items?.map((item: any, index: number) => (
                  <Box 
                    key={item.id || `item-${index}`} 
                    sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
                  >
                    <Typography variant="body2">
                      {item.title} × {item.quantity}
                    </Typography>
                    <Typography variant="body2">
                      ₹{(item.price * item.quantity).toFixed(2)}
                    </Typography>
                  </Box>
                ))}
                
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">Subtotal</Typography>
                  <Typography>₹{order?.subtotal?.toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">Shipping</Typography>
                  <Typography>₹{order?.shipping?.toFixed(2)}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1">Total</Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    ₹{order?.total?.toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack spacing={2}>
            {/* UPI Payment Option */}
            <Paper 
              elevation={0} 
              sx={{ 
                border: '1px solid #e0e0e0', 
                borderRadius: 2, 
                p: 3,
                '&:hover': { borderColor: '#000' }
              }}
            >
              <Button
                fullWidth
                onClick={() => setOpenUPIDialog(true)}
                sx={{ 
                  justifyContent: 'flex-start', 
                  textAlign: 'left',
                  p: 2
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <AccountBalanceIcon sx={{ mr: 2 }} />
                  <Box>
                    <Typography variant="subtitle1">UPI Payment</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Pay using any UPI app
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Paper>
          </Stack>
        </Grid>

      </Grid>

      <UPIDialog 
        open={openUPIDialog}
        onClose={() => setOpenUPIDialog(false)}
        onComplete={handlePaymentComplete}
        total={order?.total || 0}
        cartItems={order?.items || []}
        shippingDetails={order?.shippingDetails || {}}
        orderId={currentOrderId}
      />
    </Container>
  );
};

export default PaymentPage; 