import React, { useState, useEffect } from "react";
import { 
  Container, 
  Typography, 
  Button, 
  Paper, 
  Box,
  CircularProgress,
  Avatar,
  Divider,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  Stack,
} from "@mui/material";
import { Google as GoogleIcon, Add as AddIcon, Edit as EditIcon, LocalShipping, Inventory, ViewList, Analytics, Warehouse as InventoryIcon, Settings as SettingsIcon } from "@mui/icons-material";
import { signInWithPopup, onAuthStateChanged, User } from "firebase/auth";
import { auth, googleProvider, db, storage } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs, query, where, orderBy, limit } from "firebase/firestore";
import { OrdersView } from '../components/admin/OrdersView';
import { ProductsView } from '../components/admin/ProductsView';
import { BatchesView } from '../components/admin/BatchesView';
import { Order, OrderStatus } from '../types/admin';
import { AnalyticsView } from '../components/admin/AnalyticsView';
import { InventoryView } from '../components/admin/InventoryView';
import { SettingsView } from '../components/admin/SettingsView';

interface Product {
  id: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
  photos: string[];
  videos: string[];
  delisted?: boolean;
}


interface OrderBatch {
  id: string;
  batchNumber: number;
  orders: string[];
  finalized: boolean;
}

interface DeleteOrderDialogProps {
  open: boolean;
  orderId: string;
  onClose: () => void;
  onConfirm: () => void;
}


const AdminPage = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [profileOpen, setProfileOpen] = useState(false);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [activeTab, setActiveTab] = useState<'products' | 'orders' | 'batches' | 'analytics' | 'inventory' | 'settings'>('orders');
  const [quantityDialogOpen, setQuantityDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [newQuantity, setNewQuantity] = useState<number>(0);
  const [loadingDelist, setLoadingDelist] = useState<string>('');
  const [loadingQuantity, setLoadingQuantity] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState<string>('');
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [batches, setBatches] = useState<OrderBatch[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<OrderBatch | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState<string | null>(null);
  const [deletingOrder, setDeletingOrder] = useState(false);
  const [editProductDialogOpen, setEditProductDialogOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState<Product | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const adminDoc = await getDoc(doc(db, "admins", user.uid));
          setIsAdmin(adminDoc.exists());
          setError(null);
        } catch (error) {
          console.error("Error checking admin status:", error);
          setIsAdmin(false);
          setError("Unable to verify admin status. Please contact support if you believe this is an error.");
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      setError(null);
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      try {
        const adminDoc = await getDoc(doc(db, "admins", user.uid));
        setIsAdmin(adminDoc.exists());
        if (!adminDoc.exists()) {
          setError("You do not have administrator access. Please contact support if you believe this is an error.");
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
        setError("Unable to verify admin status. Please contact support if you believe this is an error.");
        setIsAdmin(false);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      setError("Failed to sign in. Please try again.");
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsAdmin(false);
      navigate("/admin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const ProfileSection = ({ user }: { user: User }) => (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <Avatar
            src={user.photoURL || undefined}
            alt={user.displayName || "Admin"}
            sx={{ width: 80, height: 80 }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {user.displayName}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {user.email}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="textSecondary">
            Account Created
          </Typography>
          <Typography variant="body1">
            {new Date(user.metadata.creationTime!).toLocaleDateString()}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="textSecondary">
            Last Sign In
          </Typography>
          <Typography variant="body1">
            {new Date(user.metadata.lastSignInTime!).toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const ProfileDialog = ({ open, onClose, user }: { open: boolean; onClose: () => void; user: User }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Profile Information</DialogTitle>
      <DialogContent>
        <ProfileSection user={user} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button 
          onClick={handleSignOut} 
          variant="contained" 
          color="error"
        >
          Sign Out
        </Button>
      </DialogActions>
    </Dialog>
  );

  const ProductDialog = ({ open, onClose, onProductAdded }: { 
    open: boolean; 
    onClose: () => void;
    onProductAdded: () => void;
  }) => {
    const [newProduct, setNewProduct] = useState<Partial<Product>>({
      title: '',
      description: '',
      quantity: 0,
      price: 0,
      photos: [],
      videos: []
    });
    const [uploading, setUploading] = useState(false);
    const [photoFiles, setPhotoFiles] = useState<File[]>([]);
    const [videoFiles, setVideoFiles] = useState<File[]>([]);

    const handleFileUpload = async (files: File[], type: 'photos' | 'videos') => {
      const urls: string[] = [];
      
      for (const file of files) {
        try {
          // Log file information
          console.log('Uploading file:', {
            name: file.name,
            type: file.type,
            size: file.size
          });

          // Create metadata with content type
          const metadata = {
            contentType: file.type
          };

          const timestamp = Date.now();
          const fileName = `${timestamp}_${file.name}`;
          const storageRef = ref(storage, `products/${type}/${fileName}`);
          
          // Upload with metadata
          const uploadResult = await uploadBytes(storageRef, file, metadata);
          const url = await getDownloadURL(uploadResult.ref);
          
          console.log('Upload successful:', {
            url,
            contentType: metadata.contentType
          });
          
          urls.push(url);
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          throw error;
        }
      }
      
      return urls;
    };

    const handleSave = async () => {
      try {
        setUploading(true);

        if (photoFiles.length === 0) {
          alert('Please add at least one photo');
          return;
        }

        const photoUrls = await handleFileUpload(photoFiles, 'photos');
        const videoUrls = await handleFileUpload(videoFiles, 'videos');

        const productData = {
          ...newProduct,
          photos: photoUrls,
          videos: videoUrls,
          createdAt: new Date()
        };

        await addDoc(collection(db, "products"), productData);
        onProductAdded();
        handleClose();
      } catch (error) {
        console.error("Error saving product:", error);
        alert('Error saving product. Please try again.');
      } finally {
        setUploading(false);
      }
    };

    const handleClose = () => {
      setNewProduct({
        title: '',
        description: '',
        quantity: 0,
        price: 0,
        photos: [],
        videos: []
      });
      setPhotoFiles([]);
      setVideoFiles([]);
      onClose();
    };

    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Add New Product</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Title"
              fullWidth
              value={newProduct.title}
              onChange={(e) => setNewProduct({ ...newProduct, title: e.target.value })}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={newProduct.description}
              onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
            />
            <TextField
              label="Quantity"
              type="number"
              fullWidth
              value={newProduct.quantity}
              onChange={(e) => setNewProduct({ ...newProduct, quantity: parseInt(e.target.value) })}
            />
            <TextField
              label="Price"
              type="number"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
              }}
              value={newProduct.price}
              onChange={(e) => setNewProduct({ ...newProduct, price: parseFloat(e.target.value) })}
            />
            
            {/* Photo Upload */}
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Photos (at least one required)
              </Typography>
              <input
                accept="image/*"
                type="file"
                multiple
                onChange={(e) => {
                  const files = Array.from(e.target.files || []);
                  setPhotoFiles(files);
                }}
              />
              {photoFiles.length > 0 && (
                <Typography variant="caption">
                  Selected photos: {photoFiles.map(f => f.name).join(', ')}
                </Typography>
              )}
            </Box>

            {/* Video Upload */}
            {/* <Box>
              <Typography variant="subtitle1" gutterBottom>
                Videos (optional)
              </Typography>
              <input
                accept="video/*"
                type="file"
                multiple
                onChange={(e) => {
                  const files = Array.from(e.target.files || []);
                  setVideoFiles(files);
                }}
              />
              {videoFiles.length > 0 && (
                <Typography variant="caption">
                  Selected videos: {videoFiles.map(f => f.name).join(', ')}
                </Typography>
              )}
            </Box> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={uploading}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSave}
            disabled={uploading || !newProduct.title || photoFiles.length === 0}
          >
            {uploading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const loadProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Product[];
      setProducts(productsData);
    } catch (error) {
      console.error("Error loading products:", error);
    }
  };

  const loadOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const ordersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        status: doc.data().status as OrderStatus
      })) as Order[];
      setOrders(ordersData.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate()));
    } catch (error) {
      console.error("Error loading orders:", error);
    }
  };

  const loadBatches = async () => {
    try {
      const batchesSnapshot = await getDocs(collection(db, "batches"));
      const batchesData = batchesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as OrderBatch[];
      setBatches(batchesData);
      
      // Set selected batch to latest non-finalized batch
      const latestBatch = batchesData
        .filter(batch => !batch.finalized)
        .sort((a, b) => b.batchNumber - a.batchNumber)[0];
      setSelectedBatch(latestBatch || null);
    } catch (error) {
      console.error("Error loading batches:", error);
    }
  };

  const createNewBatch = async (batchNumber: number) => {
    try {
      const newBatch: Omit<OrderBatch, 'id'> = {
        batchNumber,
        orders: [],
        finalized: false
      };
      const docRef = await addDoc(collection(db, "batches"), newBatch);
      return { id: docRef.id, ...newBatch };
    } catch (error) {
      console.error("Error creating new batch:", error);
      throw error;
    }
  };

  const getOrCreateActiveBatch = async () => {
    // Get all batches ordered by batch number
    const batchesQuery = query(
      collection(db, "batches"),
      orderBy("batchNumber", "desc"),
      limit(1)
    );
    
    const batchesSnapshot = await getDocs(batchesQuery);
    
    if (batchesSnapshot.empty) {
      // No batches exist, create first batch
      return await createNewBatch(0);
    }
    
    const latestBatch = batchesSnapshot.docs[0].data() as OrderBatch;
    const latestBatchId = batchesSnapshot.docs[0].id;
    
    if (!latestBatch.finalized) {
      // Return existing active batch
      return { ...latestBatch, id: latestBatchId };
    }
    
    // Create new batch with incremented number
    return await createNewBatch(latestBatch.batchNumber + 1);
  };

  const handleStatusUpdate = async (orderId: string, newStatus: OrderStatus) => {
    try {
      setUpdatingStatus(orderId);
      
      // If status is changing to 'order confirmed'
      if (newStatus === 'order confirmed') {
        const activeBatch = await getOrCreateActiveBatch();
        
        // Update the batch with the new order
        await updateDoc(doc(db, 'batches', activeBatch.id), {
          orders: [...activeBatch.orders, orderId]
        });
      }
      
      await updateDoc(doc(db, 'orders', orderId), {
        status: newStatus
      });
      
      await Promise.all([loadOrders(), loadBatches()]);
    } catch (error) {
      console.error('Error updating order status:', error);
      alert('Failed to update order status. Please try again.');
    } finally {
      setUpdatingStatus('');
    }
  };

  const handleDelistProduct = async (productId: string, currentDelisted: boolean) => {
    try {
      setLoadingDelist(productId);
      await updateDoc(doc(db, "products", productId), {
        delisted: !currentDelisted
      });
      await loadProducts();
    } catch (error) {
      console.error("Error updating product status:", error);
      alert('Failed to update product status. Please try again.');
    } finally {
      setLoadingDelist('');
    }
  };

  const handleQuantityUpdate = async () => {
    if (!selectedProduct) return;
    
    try {
      setLoadingQuantity(true);
      await updateDoc(doc(db, "products", selectedProduct.id), {
        quantity: newQuantity
      });
      await loadProducts();
      setQuantityDialogOpen(false);
    } catch (error) {
      console.error("Error updating quantity:", error);
      alert('Failed to update quantity. Please try again.');
    } finally {
      setLoadingQuantity(false);
    }
  };

  const finalizeBatch = async (batchId: string) => {
    try {
      await updateDoc(doc(db, 'batches', batchId), {
        finalized: true
      });
      await loadBatches();
    } catch (error) {
      console.error('Error finalizing batch:', error);
      alert('Failed to finalize batch. Please try again.');
    }
  };

  const handleDeleteOrder = async () => {
    if (!orderToDelete) return;
    
    try {
      setDeletingOrder(true);
      await deleteDoc(doc(db, "orders", orderToDelete));
      await loadOrders();
      setDeleteDialogOpen(false);
      setOrderToDelete(null);
    } catch (error) {
      console.error("Error deleting order:", error);
      alert('Failed to delete order. Please try again.');
    } finally {
      setDeletingOrder(false);
    }
  };

  const DeleteOrderDialog = ({ open, orderId, onClose, onConfirm }: DeleteOrderDialogProps) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Order</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this order? This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={deletingOrder}>Cancel</Button>
        <Button 
          onClick={onConfirm}
          color="error"
          variant="contained"
          disabled={deletingOrder}
        >
          {deletingOrder ? <CircularProgress size={24} /> : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const EditProductDialog = ({ open, onClose, product }: {
    open: boolean;
    onClose: () => void;
    product: Product;
  }) => {
    const [editedProduct, setEditedProduct] = useState({
      title: product.title,
      description: product.description
    });
    const [updating, setUpdating] = useState(false);

    const handleSave = async () => {
      try {
        setUpdating(true);
        await updateDoc(doc(db, "products", product.id), {
          title: editedProduct.title,
          description: editedProduct.description
        });
        await loadProducts();
        onClose();
      } catch (error) {
        console.error("Error updating product:", error);
        alert('Failed to update product. Please try again.');
      } finally {
        setUpdating(false);
      }
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Title"
              fullWidth
              value={editedProduct.title}
              onChange={(e) => setEditedProduct({ ...editedProduct, title: e.target.value })}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={editedProduct.description}
              onChange={(e) => setEditedProduct({ ...editedProduct, description: e.target.value })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={updating}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSave}
            disabled={updating}
          >
            {updating ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    if (isAdmin) {
      loadProducts();
      loadOrders();
      loadBatches();
    }
  }, [isAdmin]);

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Profile Icon */}
      {user && (
        <Box sx={{ 
          position: 'absolute', 
          top: 80,
          left: 16,
          zIndex: 1
        }}>
          <IconButton onClick={() => setProfileOpen(true)}>
            <Avatar
              src={user.photoURL || undefined}
              alt={user.displayName || "Admin"}
              sx={{ width: 40, height: 40 }}
            />
          </IconButton>
        </Box>
      )}

      <Paper elevation={3} sx={{ p: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Typography variant="h4" component="h1" sx={{ mb: 3 }}>
            Admin Dashboard
          </Typography>
          {isAdmin && (
            <Tabs 
              value={activeTab} 
              onChange={(_, newValue) => setActiveTab(newValue)}
              sx={{ 
                mb: 2,
                '& .MuiTab-root': {
                  minWidth: '40px',  // Reduce minimum width
                  padding: '6px',    // Reduce padding
                }
              }}
            >
              <Tab 
                icon={<Inventory />} 
                value="products"
                aria-label="Products"
              />
              <Tab 
                icon={<LocalShipping />} 
                value="orders"
                aria-label="Orders"
              />
              <Tab 
                icon={<ViewList />} 
                value="batches"
                aria-label="Batches"
              />
              <Tab 
                icon={<Analytics />} 
                value="analytics"
                aria-label="Analytics"
              />
              <Tab 
                icon={<InventoryIcon />} 
                value="inventory"
                aria-label="Inventory"
              />
              <Tab 
                icon={<SettingsIcon />} 
                value="settings"
                aria-label="Settings"
              />
            </Tabs>
          )}
        </Box>

        {!user ? (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Please sign in with your admin account
            </Typography>
            <Button
              variant="contained"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              size="large"
            >
              Sign in with Google
            </Button>
          </Box>
        ) : (
          <Box>
            {error && (
              <Typography 
                variant="body1" 
                color="error" 
                sx={{ mb: 3, textAlign: "center" }}
              >
                {error}
              </Typography>
            )}
            
            {isAdmin && (
              <>
                {activeTab === 'products' && (
                  <ProductsView
                    products={products}
                    loadingDelist={loadingDelist}
                    onAddProduct={() => setProductDialogOpen(true)}
                    onEditQuantity={(product) => {
                      setSelectedProduct(product);
                      setNewQuantity(product.quantity);
                      setQuantityDialogOpen(true);
                    }}
                    onEditProduct={(product) => {
                      setProductToEdit(product);
                      setEditProductDialogOpen(true);
                    }}
                    onDelistProduct={handleDelistProduct}
                  />
                )}
                {activeTab === 'orders' && (
                  <OrdersView
                    orders={orders}
                    selectedOrderId={selectedOrderId}
                    updatingStatus={updatingStatus}
                    handleStatusUpdate={handleStatusUpdate}
                    onDeleteOrder={(orderId) => {
                      setOrderToDelete(orderId);
                      setDeleteDialogOpen(true);
                    }}
                  />
                )}
                {activeTab === 'batches' && (
                  <BatchesView
                    batches={batches}
                    selectedBatch={selectedBatch}
                    onBatchSelect={setSelectedBatch}
                    onFinalizeBatch={finalizeBatch}
                  />
                )}
                {activeTab === 'analytics' && (
                  <AnalyticsView orders={orders} />
                )}
                {activeTab === 'inventory' && (
                  <InventoryView
                    products={products}
                    orders={orders}
                    selectedBatch={selectedBatch}
                    onProductUpdate={loadProducts}
                  />
                )}
                {activeTab === 'settings' && (
                  <SettingsView />
                )}
              </>
            )}
          </Box>
        )}
      </Paper>

      {user && (
        <>
          <ProfileDialog 
            open={profileOpen} 
            onClose={() => setProfileOpen(false)} 
            user={user} 
          />
          <ProductDialog 
            open={productDialogOpen} 
            onClose={() => setProductDialogOpen(false)}
            onProductAdded={loadProducts}
          />
          {selectedProduct && (
            <Dialog 
              open={quantityDialogOpen} 
              onClose={() => setQuantityDialogOpen(false)}
            >
              <DialogTitle>Update Quantity</DialogTitle>
              <DialogContent>
                <Box sx={{ pt: 2 }}>
                  <TextField
                    type="number"
                    label="New Quantity"
                    fullWidth
                    value={newQuantity}
                    onChange={(e) => setNewQuantity(parseInt(e.target.value) || 0)}
                    InputProps={{
                      inputProps: { min: 0 }
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button 
                  onClick={() => setQuantityDialogOpen(false)}
                  disabled={loadingQuantity}
                >
                  Cancel
                </Button>
                <Button 
                  onClick={handleQuantityUpdate}
                  variant="contained"
                  disabled={loadingQuantity}
                >
                  {loadingQuantity ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Update'
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <DeleteOrderDialog
            open={deleteDialogOpen}
            orderId={orderToDelete || ''}
            onClose={() => {
              setDeleteDialogOpen(false);
              setOrderToDelete(null);
            }}
            onConfirm={handleDeleteOrder}
          />
          {productToEdit && (
            <EditProductDialog
              open={editProductDialogOpen}
              onClose={() => {
                setEditProductDialogOpen(false);
                setProductToEdit(null);
              }}
              product={productToEdit}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default AdminPage; 