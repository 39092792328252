import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Divider,
  Stack,
  Button,
  CircularProgress
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { getCart } from '../utils/cartUtils';

interface ShippingDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postalCode: string;
}

const OrderConfirmationPage = () => {
  const navigate = useNavigate();
  const [orderId] = useState(`OZ${Math.random().toString(36).substr(2, 9).toUpperCase()}`);
  const [shippingDetails, setShippingDetails] = useState<ShippingDetails | null>(null);
  const [cartItems, setCartItems] = useState(getCart().items);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const savedDetails = localStorage.getItem('shippingDetails');
    if (savedDetails) {
      setShippingDetails(JSON.parse(savedDetails));
    }
    

  }, []);

  const subtotal = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const shipping = 100;
  const total = subtotal + shipping;


  if (!shippingDetails) {
    return null;
  }

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={0} sx={{ p: 4, border: '1px solid #e0e0e0', borderRadius: 2 }}>
        <Stack spacing={3}>
          <Box>
            <Typography variant="h6" gutterBottom>
              Shipping Details
            </Typography>
            <Typography variant="body1">
              {shippingDetails.firstName} {shippingDetails.lastName}<br />
              {shippingDetails.address}<br />
              {shippingDetails.city}, {shippingDetails.postalCode}<br />
              Phone: {shippingDetails.phone}<br />
              Email: {shippingDetails.email}
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box>
            <Typography variant="h6" gutterBottom>
              Order Details
            </Typography>
            <Typography variant="body1">
              Order ID: {orderId}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {new Date().toLocaleDateString()} at {new Date().toLocaleTimeString()}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom>
              Order Summary
            </Typography>
            {cartItems.map((item) => (
              <Box key={item.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography>
                  {item.title} × {item.quantity}
                </Typography>
                <Typography>
                  ₹{(item.price * item.quantity).toFixed(2)}
                </Typography>
              </Box>
            ))}
            <Divider sx={{ my: 2 }} />
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Subtotal</Typography>
                <Typography>₹{subtotal.toFixed(2)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Shipping</Typography>
                <Typography>₹{shipping.toFixed(2)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">₹{total.toFixed(2)}</Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ textAlign: 'center', mb: 4 }}>
            <CheckCircleIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
            <Typography variant="h4" gutterBottom>
              Order Confirmed!
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Thank you for your purchase
            </Typography>
          </Box>



        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button 
            variant="contained"
            onClick={() => navigate('/')}
            sx={{ 
              backgroundColor: 'black',
              color: 'white',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
            }}
          >
            Continue Shopping
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default OrderConfirmationPage; 