import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { 
  Container, 
  Typography, 
  Box, 
  CircularProgress,
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack
} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import Carousel from 'react-material-ui-carousel';
import { addToCart, getCartItemQuantity } from "../utils/cartUtils";

interface Product {
  id: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
  photos: string[];
  videos: string[];
}

const ProductPage = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    const loadProduct = async () => {
      try {
        if (!productId) return;
        const docRef = doc(db, "products", productId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setProduct({ id: docSnap.id, ...docSnap.data() } as Product);
        }
      } catch (error) {
        console.error("Error loading product:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProduct();
  }, [productId]);

  const handleAddToCart = () => {
    if (product) {
      addToCart({
        id: product.id,
        title: product.title,
        quantity: selectedQuantity,
        price: product.price,
        imageUrl: product.photos[0]
      });
    }
  };

  const handleBuyNow = () => {
    if (product) {
      addToCart({
        id: product.id,
        title: product.title,
        quantity: selectedQuantity,
        price: product.price,
        imageUrl: product.photos[0]
      });
      navigate('/cart');
    }
  };

  const cartQuantity = getCartItemQuantity(product?.id ?? '');
  const availableQuantities = product && product.quantity > 0
    ? Array.from({ length: Math.min(product.quantity - cartQuantity, 20) }, (_, i) => i + 1)
    : [];

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!product) {
    return (
      <Container>
        <Typography variant="h5">Product not found</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Box sx={{ maxWidth: 800, margin: "0 auto", position: 'relative' }}>
        {product.quantity <= 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              bgcolor: 'rgba(0,0,0,0.7)',
              color: 'white',
              px: 4,
              py: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="h4">SOLD OUT</Typography>
          </Box>
        )}
        <Carousel
          sx={{ 
            opacity: product.quantity <= 0 ? 0.6 : 1 
          }}
        >
          {product.photos.map((photo, index) => (
            <Paper 
              key={index} 
              sx={{ 
                height: 400, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                border: '1px solid #e0e0e0',
                borderRadius: 2,
              }}
            >
              <img
                src={photo}
                alt={`Product ${index + 1}`}
                style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
              />
            </Paper>
          ))}
        </Carousel>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h3" gutterBottom>{product.title}</Typography>
          <Typography variant="h4" color="primary" gutterBottom>
            ₹{product.price.toFixed(2)}
          </Typography>
          <Typography variant="body1" sx={{ my: 2 }}>
            {product.description}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Available: {product.quantity} units
          </Typography>
          
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="quantity-select-label">Quantity</InputLabel>
              <Select
                labelId="quantity-select-label"
                value={selectedQuantity}
                label="Quantity"
                onChange={(e) => setSelectedQuantity(Number(e.target.value))}
              >
                {availableQuantities.map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <Button 
              variant="contained" 
              color="primary" 
              size="large"
              startIcon={<AddShoppingCartIcon />}
              onClick={handleAddToCart}
              disabled={product.quantity <= 0}
              sx={{
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                }
              }}
            >
              {product.quantity <= 0 ? 'Out of Stock' : 'Add to Cart'}
            </Button>

            <Button 
              variant="contained" 
              size="large"
              onClick={handleBuyNow}
              disabled={product.quantity <= 0}
              sx={{
                backgroundColor: '#4CAF50',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#45a049',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                }
              }}
            >
              Buy Now
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default ProductPage; 