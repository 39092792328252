import React, { useEffect, useState } from "react";
import { 
  Button, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  CircularProgress,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Link
} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../utils/cartUtils";

interface Product {
  id: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
  photos: string[];
  videos: string[];
  delisted?: boolean;
}

const ProductsPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [settings, setSettings] = useState<{
    notice: {
      enabled: boolean;
      message: string;
      link?: string;
    };
  } | null>(null);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          } as Product))
          .filter((product: Product) => !product.delisted)
          .sort((a, b) => {
            // Sort products: available ones first, then sold out ones
            if (a.quantity <= 0 && b.quantity > 0) return 1;
            if (a.quantity > 0 && b.quantity <= 0) return -1;
            return 0;
          });
        setProducts(productsData);
      } catch (error) {
        console.error("Error loading products:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'global'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setSettings(data as any);
          if (data.notice?.enabled && data.notice?.message) {
            setNoticeOpen(true);
          }
        }
      } catch (error) {
        console.error('Error loading settings:', error);
      }
    };

    loadSettings();
  }, []);

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  const handleAddToCart = (product: Product) => {
    addToCart({
      id: product.id,
      title: product.title,
      quantity: 1,
      price: product.price,
      imageUrl: product.photos[0]
    });
  };

  const NoticeDialog = () => (
    <Dialog open={noticeOpen} onClose={() => setNoticeOpen(false)}>
      <DialogContent>
        <Typography>
          {settings?.notice.link ? (
            <>
              {settings.notice.message}{' '}
              <Link href={settings.notice.link} target="_blank" rel="noopener">
                Click here
              </Link>
            </>
          ) : (
            settings?.notice.message
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNoticeOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container sx={{ py: 2 }}>
      {settings?.notice.enabled && <NoticeDialog />}
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item key={product.id} xs={6}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                background: 'white',
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-5px)',
                },
                opacity: product.quantity <= 0 ? 0.6 : 1 
              }}
            >
              {product.quantity <= 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                  }}
                >
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      bgcolor: 'rgba(0,0,0,0.7)',
                      color: 'white',
                      px: 3,
                      py: 1,
                      borderRadius: 1
                    }}
                  >
                    SOLD OUT
                  </Typography>
                </Box>
              )}
              <Box sx={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={product.photos[0]}
                  alt={product.title}
                  sx={{ 
                    cursor: 'pointer',
                    objectFit: 'cover',
                  }}
                  onClick={() => navigate(`/product/${product.id}`)}
                />
              </Box>
              <CardContent sx={{ flexGrow: 1, px: 2, pt: 2, pb: '8px' }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                  {product.title}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                  ₹{product.price.toFixed(2)}
                </Typography>
                <Button 
                  variant="contained"
                  fullWidth
                  startIcon={<AddShoppingCartIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(product);
                  }}
                  sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white'
                    }
                  }}
                >
                  Quick Add
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductsPage; 