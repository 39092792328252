import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  FormControl, Select, MenuItem, Button, Box, Stack, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions, Typography
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { Order, OrderStatus } from '../../types/admin';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface OrdersViewProps {
  orders: Order[];
  selectedOrderId: string | null;
  updatingStatus: string;
  handleStatusUpdate: (orderId: string, status: OrderStatus) => Promise<void>;
  onDeleteOrder: (orderId: string) => void;
}

const createWhatsAppLink = (phone: string, orderId: string, name: string, message: string) => {
  const formattedMessage = message
    .replace('{name}', name)
    .replace('{orderId}', orderId);
  
  return `https://wa.me/${phone}?text=${encodeURIComponent(formattedMessage)}`;
};

export const OrdersView: React.FC<OrdersViewProps> = ({
  orders,
  selectedOrderId,
  updatingStatus,
  handleStatusUpdate,
  onDeleteOrder,
}) => {
  const navigate = useNavigate();
  const [whatsappMessage, setWhatsappMessage] = useState<string>('');

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'global'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setWhatsappMessage(data.whatsapp?.message || '');
        }
      } catch (error) {
        console.error('Error loading settings:', error);
      }
    };

    loadSettings();
  }, []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow 
              key={order.id}
              sx={{
                bgcolor: selectedOrderId === order.id ? 'action.selected' : 'inherit'
              }}
            >
              <TableCell>{order.id}</TableCell>
              <TableCell>
                {order.shippingDetails.firstName} {order.shippingDetails.lastName}
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FormControl size="small">
                    <Select
                      value={order.status}
                      onChange={(e) => handleStatusUpdate(order.id, e.target.value as OrderStatus)}
                      disabled={order.status === 'in cart'}
                      sx={{
                        minWidth: 200,
                        bgcolor: (() => {
                          switch (order.status) {
                            case 'in cart': return 'grey.200';
                            case 'order placed': return 'warning.light';
                            case 'order confirmed': return 'info.light';
                            case 'shipped': return 'primary.light';
                            case 'delivered': return 'success.light';
                            case 'payment error': return 'error.light';
                            default: return 'grey.100';
                          }
                        })(),
                      }}
                    >
                      <MenuItem value="in cart">In Cart</MenuItem>
                      <MenuItem value="order placed">Order Placed</MenuItem>
                      <MenuItem value="order confirmed">Order Confirmed</MenuItem>
                      <MenuItem value="shipped">Shipped</MenuItem>
                      <MenuItem value="delivered">Delivered</MenuItem>
                      <MenuItem value="payment error">Payment Error</MenuItem>
                    </Select>
                  </FormControl>
                  {updatingStatus === order.id && <CircularProgress size={20} />}
                </Box>
              </TableCell>
              <TableCell>₹{order.total.toFixed(2)}</TableCell>
              <TableCell>
                {new Date(order.createdAt.toDate()).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => navigate(`/order/${order.id}`)}
                  >
                    View Details
                  </Button>
                  {order.status === 'in cart' && (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      onClick={() => window.open(createWhatsAppLink(
                        order.shippingDetails.phone,
                        order.id,
                        order.shippingDetails.firstName,
                        whatsappMessage
                      ), '_blank')}
                    >
                      Send Reminder
                    </Button>
                  )}
                  {(order.status === 'payment error' || order.status === 'in cart') && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => onDeleteOrder(order.id)}
                    >
                      Delete
                    </Button>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}; 