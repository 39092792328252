import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Typography,
  Paper,
  CircularProgress
} from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface Settings {
  notice: {
    enabled: boolean;
    message: string;
    link?: string;
  };
  whatsapp: {
    message: string;
  };
  delivery: {
    message: string;
  };
}

export const SettingsView = () => {
  const [settings, setSettings] = useState<Settings>({
    notice: {
      enabled: false,
      message: '',
      link: ''
    },
    whatsapp: {
      message: ''
    },
    delivery: {
      message: ''
    }
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'global'));
      if (settingsDoc.exists()) {
        setSettings(settingsDoc.data() as Settings);
      }
    } catch (error) {
      console.error('Error loading settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      await setDoc(doc(db, 'settings', 'global'), settings);
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Failed to save settings');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Notice Settings
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
        <FormControlLabel
          control={
            <Switch
              checked={settings.notice.enabled}
              onChange={(e) => setSettings({
                ...settings,
                notice: {
                  ...settings.notice,
                  enabled: e.target.checked
                }
              })}
            />
          }
          label="Enable Notice"
        />

        <TextField
          label="Notice Message"
          multiline
          rows={3}
          fullWidth
          value={settings.notice.message}
          onChange={(e) => setSettings({
            ...settings,
            notice: {
              ...settings.notice,
              message: e.target.value
            }
          })}
        />

        <TextField
          label="Link (Optional)"
          fullWidth
          value={settings.notice.link || ''}
          onChange={(e) => setSettings({
            ...settings,
            notice: {
              ...settings.notice,
              link: e.target.value
            }
          })}
          placeholder="https://example.com"
        />

        <Button
          variant="contained"
          onClick={handleSave}
          disabled={saving}
          sx={{ alignSelf: 'flex-start' }}
        >
          {saving ? <CircularProgress size={24} /> : 'Save Settings'}
        </Button>
      </Box>

      <Typography variant="h6" gutterBottom>
        WhatsApp Message Settings
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Default WhatsApp Message"
          multiline
          rows={4}
          fullWidth
          value={settings.whatsapp?.message || ''}
          onChange={(e) => setSettings({
            ...settings,
            whatsapp: {
              ...settings.whatsapp,
              message: e.target.value
            }
          })}
          helperText="Use {name} and {orderId} as placeholders in your message"
        />

        <Button
          variant="contained"
          onClick={handleSave}
          disabled={saving}
          sx={{ alignSelf: 'flex-start' }}
        >
          {saving ? <CircularProgress size={24} /> : 'Save Settings'}
        </Button>
      </Box>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Delivery Date Message Settings
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Default Delivery Date Message"
          multiline
          rows={4}
          fullWidth
          value={settings.delivery?.message || ''}
          onChange={(e) => setSettings({
            ...settings,
            delivery: {
              ...settings.delivery,
              message: e.target.value
            }
          })}
          helperText="Use {date} as a placeholder for the delivery date"
        />

        <Button
          variant="contained"
          onClick={handleSave}
          disabled={saving}
          sx={{ alignSelf: 'flex-start' }}
        >
          {saving ? <CircularProgress size={24} /> : 'Save Settings'}
        </Button>
      </Box>
    </Paper>
  );
}; 