import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

interface CartItem {
  id: string;
  title: string;
  quantity: number;
  price: number;
  imageUrl: string;
}

interface Cart {
  items: CartItem[];
}

export const getCart = (): Cart => {
  const cartString = localStorage.getItem('cart');
  return cartString ? JSON.parse(cartString) : { items: [] };
};

export const checkAvailableQuantity = async (productId: string): Promise<number> => {
  const productRef = doc(db, "products", productId);
  const productDoc = await getDoc(productRef);
  
  if (productDoc.exists()) {
    return Math.min(productDoc.data().quantity, 20);
  }
  return 0;
};

export const addToCart = async (item: CartItem): Promise<void> => {
  const availableQuantity = await checkAvailableQuantity(item.id);
  const cart = getCart();
  const existingItemIndex = cart.items.findIndex(i => i.id === item.id);
  
  if (existingItemIndex >= 0) {
    // Calculate new total quantity
    const newQuantity = cart.items[existingItemIndex].quantity + item.quantity;
    // Ensure it doesn't exceed available quantity
    cart.items[existingItemIndex].quantity = Math.min(newQuantity, availableQuantity);
  } else {
    // Ensure new item quantity doesn't exceed available
    item.quantity = Math.min(item.quantity, availableQuantity);
    cart.items.push(item);
  }

  localStorage.setItem('cart', JSON.stringify(cart));
  window.dispatchEvent(new CustomEvent('cartUpdated'));
};

export const getCartItemsCount = (): number => {
  const cart = getCart();
  return cart.items.reduce((total, item) => total + item.quantity, 0);
};

export const getCartItemQuantity = (productId: string): number => {
  const cart = getCart();
  const item = cart.items.find(i => i.id === productId);
  return item ? item.quantity : 0;
}; 