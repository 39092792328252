import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { Box, Typography, Stack } from '@mui/material';
import { Order } from '../../types/admin';

interface AnalyticsViewProps {
  orders: Order[];
}

interface DailyData {
  date: string;
  confirmed: number;
  inCart: number;
}

export const AnalyticsView: React.FC<AnalyticsViewProps> = ({ orders }) => {
  const [dailyData, setDailyData] = useState<DailyData[]>([]);
  const [totals, setTotals] = useState({ confirmed: 0, inCart: 0 });

  useEffect(() => {
    const processOrders = () => {
      const dailyTotals: { [key: string]: { confirmed: number; inCart: number } } = {};
      let confirmedTotal = 0;
      let inCartTotal = 0;

      orders.forEach(order => {
        const date = new Date(order.createdAt.toDate()).toLocaleDateString();
        
        if (!dailyTotals[date]) {
          dailyTotals[date] = { confirmed: 0, inCart: 0 };
        }

        if (order.status === 'order confirmed') {
          dailyTotals[date].confirmed += order.total;
          confirmedTotal += order.total;
        } else if (order.status === 'in cart') {
          dailyTotals[date].inCart += order.total;
          inCartTotal += order.total;
        }
      });

      setTotals({ confirmed: confirmedTotal, inCart: inCartTotal });

      const sortedData = Object.entries(dailyTotals)
        .map(([date, totals]) => ({
          date,
          confirmed: totals.confirmed,
          inCart: totals.inCart
        }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      setDailyData(sortedData);
    };

    processOrders();
  }, [orders]);

  const formatYAxis = (value: number) => {
    return value >= 1000 ? `${(value / 1000).toFixed(0)}K` : value.toString();
  };

  return (
    <Box sx={{ width: '100%', height: 400 }}>
      <Stack direction="row" spacing={4} sx={{ mb: 2 }}>
        <Typography variant="h6" color="success.main">
          Total Confirmed: ₹{totals.confirmed >= 1000 
            ? `${(totals.confirmed / 1000).toFixed(1)}K` 
            : totals.confirmed.toFixed(2)}
        </Typography>
        <Typography variant="h6" color="warning.main">
          Total In Cart: ₹{totals.inCart >= 1000 
            ? `${(totals.inCart / 1000).toFixed(1)}K` 
            : totals.inCart.toFixed(2)}
        </Typography>
      </Stack>
      <ResponsiveContainer>
        <BarChart data={dailyData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            angle={-90}
            textAnchor="end"
            height={70}
            interval={0}
          />
          <YAxis 
            label={{ 
              value: 'Total Value (₹)', 
              angle: -90, 
              position: 'insideLeft' 
            }}
            tickFormatter={formatYAxis}
          />
          <Tooltip 
            formatter={(value: number) => `₹${value.toFixed(2)}`}
          />
          <Legend />
          <Bar 
            dataKey="confirmed" 
            stackId="a" 
            fill="#4caf50" 
            name="Confirmed Orders"
          />
          <Bar 
            dataKey="inCart" 
            stackId="a" 
            fill="#ff9800" 
            name="In Cart"
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}; 