import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  IconButton,
  Button,
  Select,
  MenuItem,
  Divider,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import { getCart, addToCart } from '../utils/cartUtils';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';
import CircularProgress from '@mui/material/CircularProgress';

const functions = getFunctions();


interface CartItem {
  id: string;
  title: string;
  quantity: number;
  price: number;
  imageUrl: string;
}

interface ShippingDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
}

const CartPage = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [isShippingExpanded, setIsShippingExpanded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [shippingDetails, setShippingDetails] = useState(() => {
    const savedDetails = localStorage.getItem('shippingDetails');
    return savedDetails ? JSON.parse(savedDetails) : {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      postalCode: ''
    };
  });
  const [availableQuantities, setAvailableQuantities] = useState<{[key: string]: number}>({});
  const [isCheckingOut, setIsCheckingOut] = useState(false);

  useEffect(() => {
    localStorage.setItem('shippingDetails', JSON.stringify(shippingDetails));
  }, [shippingDetails]);

  useEffect(() => {
    const loadCart = async () => {
      const cart = getCart();
      setCartItems(cart.items);
      
      // Fetch available quantities for all cart items
      for (const item of cart.items) {
        const productRef = doc(db, "products", item.id);
        const productDoc = await getDoc(productRef);
        if (productDoc.exists()) {
          const availableQuantity = Math.min(productDoc.data().quantity, 20);
          setAvailableQuantities(prev => ({...prev, [item.id]: availableQuantity}));
        }
      }
    };

    loadCart();
    window.addEventListener('cartUpdated', loadCart);
    return () => window.removeEventListener('cartUpdated', loadCart);
  }, []);

  const updateQuantity = async (item: CartItem, newQuantity: number) => {
    const productRef = doc(db, "products", item.id);
    const productDoc = await getDoc(productRef);
    
    if (productDoc.exists()) {
      const availableQuantity = Math.min(productDoc.data().quantity, 20);
      setAvailableQuantities(prev => ({...prev, [item.id]: availableQuantity}));
      
      // If requested quantity exceeds available, remove item
      if (availableQuantity === 0) {
        removeItem(item.id);
        return;
      }
      
      // If new quantity is valid, update it
      if (newQuantity <= availableQuantity) {
        addToCart({ ...item, quantity: newQuantity - item.quantity });
      }
    }
  };

  const removeItem = (itemId: string) => {
    const cart = getCart();
    cart.items = cart.items.filter(item => item.id !== itemId);
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new CustomEvent('cartUpdated'));
  };

  const subtotal = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const shipping = 100; // Fixed shipping cost of ₹99
  const total = subtotal + shipping;

  const handleCheckout = async () => {
    const isFormComplete = Object.values(shippingDetails).every((value: any) => value.trim() !== '');
    if (!isFormComplete) {
      setIsShippingExpanded(true);
      setShowError(true);
      document.getElementById('shipping-section')?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    setIsCheckingOut(true); // Start loading
    try {
      const orderData = {
        items: cartItems.map(item => ({
          productId: item.id,
          quantity: item.quantity,
          title: item.title
        })),
        shippingDetails
      };

      const createOrderFunction = httpsCallable<typeof orderData, { orderId: string }>(functions, 'createOrder');
      const result = await createOrderFunction(orderData);
      
      localStorage.setItem('pendingOrder', result.data.orderId);
      localStorage.setItem('cart', JSON.stringify({ items: [] }));
      window.dispatchEvent(new CustomEvent('cartUpdated'));
      
      navigate(`/payment/${result.data.orderId}`);
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Error creating order. Please try again.');
    } finally {
      setIsCheckingOut(false); // End loading
    }
  };

  const handleInputChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setShippingDetails((prev: any) => ({
      ...prev,
      [field]: event.target.value
    }));
    setShowError(false);
  };

  if (cartItems.length === 0) {
    return (
      <Container sx={{ py: 8, minHeight: '80vh' }}>
        <Typography variant="h5" textAlign="center" gutterBottom>
          Your cart is empty
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="contained" href="/" sx={{ 
            backgroundColor: 'black',
            color: 'white',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
          }}>
            Continue Shopping
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Shopping Cart
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper elevation={0} sx={{ border: '1px solid #e0e0e0', borderRadius: 2, p: 2 }}>
            {cartItems.map((item) => (
              <Box key={item.id}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4} sm={3}>
                    <Box 
                      component="a" 
                      href={`/product/${item.id}`} 
                      sx={{ 
                        cursor: 'pointer',
                        textDecoration: 'none',
                        display: 'block'
                      }}
                    >
                      <img 
                        src={item.imageUrl} 
                        alt={item.title}
                        style={{ width: '100%', borderRadius: 8 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={4}>
                    <Box 
                      component="a" 
                      href={`/product/${item.id}`} 
                      sx={{ 
                        cursor: 'pointer',
                        textDecoration: 'none',
                        color: 'inherit'
                      }}
                    >
                      <Typography variant="subtitle1">{item.title}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        ₹{item.price.toFixed(2)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Select
                      value={item.quantity}
                      onChange={(e) => updateQuantity(item, Number(e.target.value))}
                      size="small"
                      sx={{ minWidth: 80 }}
                    >
                      {Array.from({ length: Math.max(1, availableQuantities[item.id] || 20) }).map((_, i) => (
                        <MenuItem 
                          key={i + 1} 
                          value={i + 1}
                          disabled={i + 1 > (availableQuantities[item.id] || 20)}
                        >
                          {i + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Stack 
                      direction="row" 
                      spacing={1} 
                      alignItems="center"
                      justifyContent={{ xs: 'flex-end', sm: 'flex-start' }}
                    >
                      <Typography variant="subtitle1">
                        ₹{(item.price * item.quantity).toFixed(2)}
                      </Typography>
                      <IconButton 
                        onClick={() => removeItem(item.id)}
                        size="small"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item xs={12} md={8} id="shipping-section">
          <Paper elevation={0} sx={{ border: '1px solid #e0e0e0', borderRadius: 2, p: 3, mb: 3 }}>
            <Box
              onClick={() => setIsShippingExpanded(!isShippingExpanded)}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variant="h6">
                Shipping Details
              </Typography>
              <ExpandMoreIcon
                sx={{
                  transform: isShippingExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              />
            </Box>

            {showError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Please fill out all shipping details before proceeding
              </Alert>
            )}

            <Collapse in={isShippingExpanded}>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    size="small"
                    value={shippingDetails.firstName}
                    onChange={handleInputChange('firstName')}
                    error={showError && !shippingDetails.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    value={shippingDetails.lastName}
                    onChange={handleInputChange('lastName')}
                    error={showError && !shippingDetails.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    size="small"
                    type="email"
                    value={shippingDetails.email}
                    onChange={handleInputChange('email')}
                    error={showError && !shippingDetails.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    size="small"
                    value={shippingDetails.phone}
                    onChange={handleInputChange('phone')}
                    error={showError && !shippingDetails.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                    value={shippingDetails.address}
                    onChange={handleInputChange('address')}
                    error={showError && !shippingDetails.address}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="City"
                    variant="outlined"
                    size="small"
                    value={shippingDetails.city}
                    onChange={handleInputChange('city')}
                    error={showError && !shippingDetails.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="State/Province"
                    variant="outlined"
                    size="small"
                    value={shippingDetails.state}
                    onChange={handleInputChange('state')}
                    error={showError && !shippingDetails.state}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Postal Code"
                    variant="outlined"
                    size="small"
                    value={shippingDetails.postalCode}
                    onChange={handleInputChange('postalCode')}
                    error={showError && !shippingDetails.postalCode}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={0} sx={{ border: '1px solid #e0e0e0', borderRadius: 2, p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Order Summary
            </Typography>
            <Box sx={{ my: 2 }}>
              <Stack spacing={2}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">Subtotal</Typography>
                  <Typography>₹{subtotal.toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">Shipping</Typography>
                  <Typography>₹{shipping.toFixed(2)}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1">Total</Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    ₹{total.toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Button 
              fullWidth 
              variant="contained" 
              size="large"
              onClick={handleCheckout}
              disabled={isCheckingOut}
              sx={{ 
                mt: 2,
                backgroundColor: 'black',
                color: 'white',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
              }}
            >
              {isCheckingOut ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Checkout'
              )}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CartPage; 